(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/dialog-wrapper/dialog-wrapper.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/dialog-wrapper/dialog-wrapper.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  Confirm,
  branding,
  message,
  api,
  type
} = svs.ui.dialog;
const {
  useEffect
} = React;
const DialogWrapper = _ref => {
  let {
    onClose,
    dialogInfo
  } = _ref;
  useEffect(() => {
    const messages = [];
    if (dialogInfo.text) {
      messages.push({
        type: message.TEXT,
        text: dialogInfo.text
      });
    }
    if (dialogInfo.title.length === 0 && messages.length === 0) {
      return;
    }
    const iconObj = {};
    if (dialogInfo.dialogType !== 'error' && dialogInfo.icon) {
      iconObj.icon = dialogInfo.icon;
    }
    const dialogButtons = (dialogInfo.buttons || []).slice(0);
    if (dialogButtons.length === 0) {
      dialogButtons.push({
        text: 'Okej'
      });
    }
    const dialog = new Confirm(_objectSpread(_objectSpread({
      branding: branding.SPORT
    }, iconObj), {}, {
      title: dialogInfo.title || '',
      message: messages,
      type: dialogInfo.dialogType === 'error' ? type.ERROR : type.DEFAULT,
      actions: dialogButtons.map(button => ({
        title: button.text,
        callback: () => {
          button.callback && button.callback();
          dialog.close();
        }
      }))
    }));
    dialog.on('closed', null, () => {
      onClose();
    });
    api.add(dialog);
    return () => {
      dialog.close();
      dialog.off('closed');
    };
  }, [onClose, dialogInfo]);
  return null;
};
setGlobal('svs.components.marketplaceShared.dialogWrapper.DialogWrapper', DialogWrapper);

 })(window);